.footer{
    display: flex;
    justify-content: space-around;
    background: #3D3E4A;
    margin-top: 3rem;
    padding-top: 2rem;
    padding-bottom: 3rem;
    color: white;
}

.footer-1, .footer-2, .footer-3, .footer-4{
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.footer-1 span, .footer-2 span, .footer-3 span, .footer-4 span{
    font-weight: 200;
    font-size: 1.1rem;
}

h2{
    font-weight: bold;
    font-size: 1.5rem;
}

.footer-1 span:nth-child(1){
    color: var(--pink);
    font-size:2.5rem;
    font-weight: bold;
}

.footer-icons{
    padding-bottom: 1rem;

}

.footer-1 .icon{
    color: white;
    margin-right: 2.5rem;
    height: 1.5rem;
    width: 1.5rem;
}






@media screen and (max-width: 780px){
    .footer{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }

    .footer-1 span:nth-child(1), .footer-1 h1{
        font-size:xx-large;
    }
    
    .footer-1 span, .footer-2 span, .footer-3 span, .footer-4 span{
        font-size: small;
    }

    h2{
        font-size: medium;
    }

    .footer-1, .footer-2, .footer-3, .footer-4{
        justify-content: center;
        align-items: center;
    }

    .footer-1 .icon{
        margin: 1rem;
        margin-bottom:  0;
        margin-top: 0;
        height: 1.3rem;
        width: 1.3rem;
   }
}