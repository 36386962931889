.products{
    margin-right: 7rem;
    margin-left: 7rem;
    display: flex;
    flex-direction: column;
}
.products-top{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.products-list ul{
    display: flex;
    align-items: center;
    justify-content: center;
}

.products-list ul li{
    display: inline;
    padding: 3rem;
    font-size: 1.3rem;
    font-weight: 400;
   
}
.products-list ul li:hover{
    cursor: pointer;
    color: var(--pink);
}


.products-arrows{
    display: flex;
    align-items: center;
    justify-content: center;
}
.products-arrows .icon{
    height: 1.8rem;
    width: 1.8rem;
    color: gray;
    padding: .5rem;
}
.products-arrows .icon:hover{
    cursor: pointer;
    color: var(--pink);
}

.products-botton{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2.5rem;
}
.products-space, .products-space1{
    display: flex;
    gap: 5rem;
    padding-bottom: 2rem;
    cursor: pointer;
    
}

.products-space1{
    position: relative;
}


.product-img{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #E2E1DD;
    width: 15rem;
    height: 15rem;
    padding: 3rem;  
     
}

.product img{
    width: 12rem;
    height: 12rem;
}

.product-text{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.product-text>span:nth-of-type(1){
    font-size: 18px;
}

.product-text>span:nth-of-type(2){
    font-weight: 700;
    color: var(--black);
}

.product-sold{
    display: flex;
    justify-content: center;
    color: white;
    background-color: #F5052F;
    position: absolute;
    width: 3rem;
    padding: 0.4rem;
    font-size:0.9rem;
    transform: rotate(-90deg);
    left: -1.8rem;
    top: -0.3rem;
}
.product-sold1{
    display: flex;
    justify-content: center;
    color: white;
    background-color: var(--pink);
    position: absolute;
    width: 3rem;
    padding: 0.4rem;
    font-size:0.9rem;
    transform: rotate(-90deg);  
    right:18rem
}
.product-icon{
    color: #F5052F;
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    left: 44rem;
    top: -4rem;
}








@media screen and (max-width: 780px){
    .products{
        margin-right: 0;
        margin-left: 0;
        align-items: center;
        justify-content: center;
        }

    .products-top{
        flex-direction: column;
    }

    .products-list ul{
        flex-direction: column;
    }

    .products-list ul li{
        padding: .5rem;
        font-size: medium;
        font-weight: 400;
          
    }

    .products-arrows{
        display: none;
    }

   .product-text{
    padding-bottom: 1rem;
   }

    .product-img{
        width: 10rem;
        height: 10rem;
        padding: 1rem;  
    }

    .product img{
        width: 9rem;
        height: 9rem;
    }

    .products-space, .products-space1{
        gap: 0;
        padding-bottom: 1rem;
        flex-direction: column;
        
    }

    .product-sold1{
        display: none;
    }


    .product-text>span:nth-of-type(1){
        font-size: small;
    }
    
    .product-text>span:nth-of-type(2){
        font-weight: 700;
        color: var(--black);
        font-size: medium;
    }

    .product-sold{
        font-size: small
    }
    .product-icon{
        display: none;
    }

}