.collection{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5rem;
    margin-right: 20rem;
    margin-left: 20rem;
    background: #E2E1DD;
    position: relative;
}

.col-left{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 3rem;
    margin-left: 6rem;
}

.col-left h1{
    color: var(--black);
    line-height: 2rem;
}

.col-left span{
    color: var(--black);
    font-size: 1rem;
    padding-bottom: 1rem;
}

.col-left button{
    padding: 1rem;
    color: white;
    border:none;
    font-size: 1rem;
    width: 50%;
    background-color: var(--pink);
    font-weight: bold;
    cursor: pointer;
}

.col-right img{
    transform: scale(2.2);
    position: absolute;
    right: 12rem;
    top: 1rem;
}


@media screen and (max-width: 780px){
    .collection{
        margin-right: 0;
        margin-left: 0;
        margin: 1rem;
        justify-content: center;
       
    }
  
    .col-left h1{
        padding-top: 0.5rem;
        font-size: xx-large;
    }

    .col-left span{
        font-size: small;
    }

    .col-right img{
        transform: scale(0.7);
        right: -1.5rem;
        top:-4rem;
        }
    .col-left button{
        width: 60%;
        font-size: small;
        padding: .7rem;
        margin-right: 2rem;
    }

}