:root{
  --pink: #F56082;
  --black: #343434;
  --bg: #F5F5F5;
  --shadow1: 0px 13px 48px -1px rgba(0, 0, 0, 0.25);
  --shadow2:  0px 13px 46px rgba(0, 0, 0, 0.13);
}

.App{
  display: flex;
  flex-direction: column; 

}
::-webkit-scrollbar{
  height: 12px;
  width: 12px;
  }
  
  ::-webkit-scrollbar-track{
  background: var(--bg);
  border-radius: 1px;
  }

    ::-webkit-scrollbar-thumb{
  background: var(--pink);
  border-radius: 1ex;
  }

   a{
    text-decoration: none;
    color: inherit
  }